<template>
  <section class="hero-image">
    <div class="hero-txt">
      <h1>Save money on your next business trip?</h1>
      <p>Book smart, stay smart</p>
      <div class="great-offers">
        <b-form inline @submit.prevent="findPlaces">
          <b-input-group>
            <b-form-input
              id="inline-form-input-name"
              class="banner-input-rule"
              autocomplete="off"
              placeholder="Where are you going?"
              v-model="destination"
            ></b-form-input>
            <b-button type="submit" variant="primary" class="find"
              >Find great offers</b-button
            >
          </b-input-group>
        </b-form>
      </div>
    </div>
  </section>
</template>
<style>
.disabled {
  color: #ddd !important;
  cursor: default;
}
.pac-container:after {
  background-image: none !important;
  height: 0;
}
</style>
<script>
import {Loader} from '@googlemaps/js-api-loader';

export default {
  name: "HeroSection",

  components: {},
  data() {
    return {
      format: "dd MM yyyy",
      destination: '',
      location: {
        latitude: 0,
        longitude: 0,
      },
    };
  },

  watch: {},
  methods: {

    init() {
      this.loader
        .load()
        .then(() => {
          this.autocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById('inline-form-input-name')
          );

          this.autocomplete.addListener("place_changed", () => {
            const place = this.autocomplete.getPlace();
            this.location.latitude = place.geometry.location.lat();
            this.location.longitude = place.geometry.location.lng();
          });

        })
        .catch(error => {
          console.error(error)
        });
    },

    findPlaces() {
      console.log('location :',
        this.destination,
        this.location.longitude,
        this.location.latitude,
      )
    },

    changeYear() {
      const week_name = document.getElementsByClassName("cell month");
      for (let i = 0; i < week_name.length; i++) {
        week_name[i].innerHTML = week_name[i].innerHTML.substr(0, 3);
      }
    },
    handleClick(e) {
      if (e.target.matches(".vdp-datepicker__calendar,.prev,.next")) {

        const week_name = document.getElementsByClassName("cell month");
        for (let i = 0; i < week_name.length; i++) {
          week_name[i].innerHTML = week_name[i].innerHTML.substr(0, 3);
        }
      }
    },
  },
  created() {
    this.loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
      version: "weekly",
      libraries: ['places']
    });
  },

  mounted() {
    this.init();
  },
  beforeDestroy() {
    [this.autocomplete].map((item) => {
      window.google.maps.event.clearInstanceListeners(item);
    })
  }
};
</script>
