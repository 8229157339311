<template>
  <div>
    <section class="popular-cities">
      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="section-title">most popular cities</h2>
            <p>Book smart, stay smart</p>
          </div>
        </div>

        <div class="row images-blocks">
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="#" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 24.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <router-link to="/prop_list"
                ><h4 class="pos-title">Berlin</h4></router-link
              >
            </a>
          </div>
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="#" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 25.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <h4 class="pos-title">Karlsruhe</h4>
            </a>
          </div>
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="#" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 26.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <h4 class="pos-title">Frankfurt am main</h4>
            </a>
          </div>
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="#" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 27.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <h4 class="pos-title">Colonge</h4>
            </a>
          </div>
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="#" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 28.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <h4 class="pos-title">Dusseldorf</h4>
            </a>
          </div>
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="#" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 29.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <h4 class="pos-title">Dresden</h4>
            </a>
          </div>
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="#" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 30.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <h4 class="pos-title">Hannover</h4>
            </a>
          </div>
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="#" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 31.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <h4 class="pos-title">Kassel</h4>
            </a>
          </div>
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="#" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 32.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <h4 class="pos-title">Tubinger</h4>
            </a>
          </div>
        </div>

        <div class="row miss-out">
          <div class="col-12 text-center">
            <h2 class="section-title">Don’t want to miss out?</h2>
            <p>You’ll know when an incredible offer appear</p>
            <div class="great-offers">
              <b-form inline>
                <b-input-group>
                  <b-form-input
                    id="inline-form-input-name"
                    class="banner-input-rule"
                    placeholder="Your best email"
                  ></b-form-input>
                  <b-button variant="primary" class="find"
                    >Get updates on new offers</b-button
                  >
                </b-input-group>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about">
      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="section-title color-fff">About gloveler</h2>
            <p>You’ll know when an incredible offer appears</p>
          </div>
        </div>
        <div class="row about-inner">
          <div class="col-md-6 col-lg-6 block-outer">
            <b-card
              title="What is gloveler?"
              img-src="../assets/images/Frame 35.png"
              img-alt="Image"
              img-top
              tag="article"
            >
              <b-card-text>
                Gloveler is a worldwide platform where you can offer and book
                private accommodation. Many people around the world already use
                the secure service. Find out how it works!
              </b-card-text>

              <b-button href="#">Learn more</b-button>
            </b-card>
          </div>
          <div class="col-md-6 col-lg-6 block-outer">
            <b-card
              title="List your accommodation for free"
              img-src="../assets/images/Frame 39.png"
              img-alt="Image"
              img-top
              tag="article"
            >
              <b-card-text>
                Advertise your accommodation free of charge, whether offering a
                guest house, an apartment, a room in a private home, or just a
                couch!
              </b-card-text>

              <b-button href="#">List a property</b-button>
            </b-card>
          </div>
        </div>
      </div>
    </section>

    <section class="why-glovier">
      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="section-title color-444">Why gloveler?</h2>
            <p>You’ll know when an incredible offer appears</p>
          </div>
        </div>

        <div class="row tick-outer">
          <div class="col-md-4 text-left">
            <span><img src="../assets/images/tick.png" /></span>
            <h4 class="card-title">Cheaper than a hotel</h4>
            <p class="card-text">
              Private accommodations are on average 50% cheaper than a hotel of
              the same standard. And most accommodations are in the city center
              and have a kitchen.
            </p>
          </div>
          <div class="col-md-4 text-left">
            <span><img src="../assets/images/tick.png" /></span>
            <h4 class="card-title">In the thick of it</h4>
            <p class="card-text">
              Travelling as a guest, not as an anonymous tourist. This wish
              becomes true with private accommodations. For sure as individual
              as your journey.
            </p>
          </div>
          <div class="col-md-4 text-left">
            <span><img src="../assets/images/tick.png" /></span>
            <h4 class="card-title">
              Thousands of private accommodations worldwide
            </h4>
            <p class="card-text">
              Gloveler is the oldest provider of private accommodations in
              Germany. Benefit from our experience, our approved booking process
              and our fast customer service.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="logos-sec">
      <div class="container-fluid plr-80 text-center">
        <ul class="d-flex">
          <li><img src="../assets/images/brand 1.png" alt="" /></li>
          <li><img src="../assets/images/european-union 1.png" alt="" /></li>
          <li>
            <img src="../assets/images/n-tv-seeklogo.com-2 1.png" alt="" />
          </li>
          <li>
            <img
              src="../assets/images/wirtschaftswoche-seeklogo.com 1.png"
              alt=""
            />
          </li>
          <li>
            <img src="../assets/images/1598px-VDIVDE-IT_Logo 1.png" alt="" />
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "mainSec",
  components: {},
};
</script>
<style >
</style>